import { InputSubmitArg, AutocompleteSubmitData, InputChangeArg } from 'types/inputs'
import { autocompleteSubmitArg, autocompleteChangeArg, autocompleteFieldDefault } from 'utils/inputs'
import {
    ProjectRoomsAutocompleteKey,
    ProjectRoomsAutocompleteFields,
    ProjectRoomWorkFormulaFormBody,
    ProjectRoom,
    ProjectWork,
    RoomWithWorks,
    NewProjectWork,
    RoomsTabs, RoomsTabsPath, RoomsTypes
} from './ProjectRooms.types'
import { FindWorkFormulaResponseDto, FindWorkResponseDto, useLazyOrderRoomsFindByProjectQuery } from 'store/api/orderRoom'
import { CreateWorkFormulaRequestDto } from 'store/api/projectWorkFormulas'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'hooks/redux.ts'
import { projectsSlice } from 'store/slices/projectsSlice.ts'
import { useLazyJobGroupsFindByProjectQuery } from 'store/api/jobGroups.tsx';
import { useLazyProjectWorkStagesFindByProjectQuery } from 'store/api/projectWorkStages.tsx';

export const roomsTabs: RoomsTabs[]  = [
    { value: 'works_by_room', label: 'По помещениям' },
    { value: 'works_by_stage', label: 'По этапам' },
    { value: 'works_by_group', label: 'По группам' }
]

export const roomsType = (pathname: RoomsTabsPath): RoomsTypes =>  pathname.includes('works_by_room')
    ? 'ROOM'
    : pathname.includes('works_by_stage')
        ? 'STAGE'
        : pathname.includes('works_by_group')
            ? 'GROUP' :
            'ROOM'

export const convertRoomToStoreFormat = (room: RoomWithWorks): ProjectRoom => (
    {
        ...room, 
        works: room.works?.map(work => (
            {
                ...work,
                formulas: work.formulas?.map(f => convertProjectRoomWorkFormulaToFormBody(f))
            }
        )
        ) || []
    }
)

export const convertRoomWorkToStoreFormat = (work: FindWorkResponseDto): ProjectWork => (
    {
        ...work, 
        formulas: work?.formulas ? [...work.formulas]?.map(f => convertProjectRoomWorkFormulaToFormBody(f)) : [],
    }
)

const convertProjectRoomWorkFormulaToFormBody = (roomWorkFormula: FindWorkFormulaResponseDto): ProjectRoomWorkFormulaFormBody => ({
    ...roomWorkFormula,
    units: roomWorkFormula?.units ? { name: roomWorkFormula.units.name, data: { id: roomWorkFormula.units.id, name: roomWorkFormula.units.name } } : autocompleteFieldDefault,
})

export const convertProjectRoomWorkFormulaToRequestDto = (data: ProjectRoomWorkFormulaFormBody & { workId: string, jobId: string | null | undefined }): CreateWorkFormulaRequestDto => ({
    name: data?.name || projectRoomsInitialState.name || '',
    materialId: data?.material?.id || projectRoomsInitialState.material?.id || '',
    typeId: data?.type?.id || projectRoomsInitialState.type?.id || '',
    unitsId: data?.units?.data?.id || projectRoomsInitialState.units?.name || '',
    value: data?.value || projectRoomsInitialState.value || [],
    resourceFormulaId: data?.jobId,
    workId: data?.workId,
})

export const onProjectWorkFormulaAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<ProjectRoomsAutocompleteKey, ProjectRoomsAutocompleteFields>) => (data: AutocompleteSubmitData) => {
    setFormValue(key, autocompleteSubmitArg(data))
}
export const onProjectBodyWorkFormulaAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<ProjectRoomsAutocompleteKey, ProjectRoomsAutocompleteFields>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(key, autocompleteChangeArg(label))
    }
}

export const isProjectWork = ( // not used now (not reliable because of types difference)
    work: ProjectWork | NewProjectWork
): work is ProjectWork => !!(work as ProjectWork)?.room?.id

export const isNewProjectWork = ( // not used now (not reliable because of types difference)
    work: ProjectWork | NewProjectWork
): work is NewProjectWork => {
    const typedWork = work as ProjectWork

    if (typedWork?.project?.id || typedWork?.room?.id || typedWork?.formulas || typedWork?.workingHours) {
        return false
    } else {
        return true
    }
}

export const useWorksInitialFind = () => {
    const dispatch = useAppDispatch()
    const { pathname, key } = useLocation()
    const { projectId } = useParams()

    const isEditPage = pathname.includes('edit')
    const isWorksByRoomPage = pathname.includes('works_by_room')
    const isWorksByGroupPage = pathname.includes('works_by_group')

    const editableFormBody = useAppSelector((state) => state.projectsReducer.editableFormBody)
    const { setEditableFormBody } = projectsSlice.actions

    const [isLoading, setIsLoading]= useState<boolean>(true)

    const [findProjectsByRoom] = useLazyOrderRoomsFindByProjectQuery()
    const [findProjectsByGroup] = useLazyJobGroupsFindByProjectQuery()
    const [findProjectsByStage] = useLazyProjectWorkStagesFindByProjectQuery()

    useEffect(
        function initialPageLoad() {
            setIsLoading(true)
            if(projectId) {
                if(isWorksByRoomPage || isEditPage) {
                    findProjectsByRoom(projectId)
                        .unwrap()
                        .then((res) => {
                            dispatch(
                                setEditableFormBody({
                                    ...editableFormBody,
                                    rooms: [...res.data].map((r) => ({
                                        ...r,
                                        works: r.works.map(convertRoomWorkToStoreFormat),
                                    })),
                                }),
                            )
                        })
                        .finally(() => setIsLoading(false))
                } else if(isWorksByGroupPage) {
                    findProjectsByGroup(projectId)
                        .unwrap()
                        .then((res) => {
                            dispatch(
                                setEditableFormBody({
                                    rooms: [...res.data].map((r, i) => ({
                                        ...r,
                                        id: r.id ? r.id : `otherGroup-${i}`,
                                        square: 0,
                                        works: r.works.map(convertRoomWorkToStoreFormat),
                                    })),
                                }),
                            )
                        })
                        .finally(() => setIsLoading(false))
                } else {
                    findProjectsByStage(projectId)
                        .unwrap()
                        .then((res) => {
                            dispatch(
                                setEditableFormBody({
                                    rooms: [...res.data].map((r, i) => ({
                                        ...r,
                                        id: r.id ? r.id : `otherStage-${i}`,
                                        square: 0,
                                        works: r.works.map(convertRoomWorkToStoreFormat),
                                    })),
                                }),
                            )
                        })
                        .finally(() => setIsLoading(false))
                }
            } else {
                dispatch(setEditableFormBody({ rooms: [] }))
            }
        },
        [
            key
        ],
    )

    return [isLoading]
}

export const projectRoomsInitialState: Partial<ProjectRoomWorkFormulaFormBody> = {
    id: '',
    name: '',
    value: [],
    type: {
        id: '',
        name: '',
        colour: '',
        description: '',
        type: 'SPECIAL',
    },
    workName: autocompleteFieldDefault,
    units: autocompleteFieldDefault,
}