import { Badge } from '@mantine/core'
import { workStatusColors } from 'components/Worker/Profile/Money/MoneyTab.utils.tsx'
import React from 'react'
import { WorkStatus } from '../Profile/Money/MoneyTab.utils.tsx'

type WorkMoneyComponentProps = {
    workStatus?: WorkStatus | null
    workMoney?: number
}

export const WorkMoneyComponent: React.FC<WorkMoneyComponentProps> = ({ workStatus, workMoney }) => {

    // refactor, the similar function is in user's money, maybe need to use smth from mantine helpers or create some global helper function
    const money = workMoney ? `${(Math.round(workMoney * 100 + Number.EPSILON)/100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ₽` : 'У исполнителя'

    return (
        <Badge size={'lg'} radius={4} px={4} py={8} fw="500" fz="xs" sx={(theme) => ({
            ...workStatusColors(workStatus || 'PREPARATION', theme),
            textTransform: 'none'
        })}>
            { money }
        </Badge>
    )
}
