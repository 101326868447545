import { MantineTheme } from '@mantine/core';
import { PerformerFinanceSummary } from 'store/api/performer.tsx';
import {
    IconCheck,
    IconRefresh,
    IconInfoTriangle,
    IconPlayerPlay,
    IconClock,
    IconCurrencyRubel
} from '@tabler/icons-react'

// *
// UI
// *

export type MoneyTabsPath =
    'projects'| 'charts'
export type MoneyTabs = { [key in MoneyTabsPath]: string }

// *
// TABS FUNCS
// *

export const moneyTabs: MoneyTabs = {
    projects: 'Проекты',
    charts: 'Статистика'
}

// *
// MONEY FORMATTER
// *

export const formatMoneyValue = (value: number ) => `${(Math.round(value * 100 + Number.EPSILON)/100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} руб.`

// *
// SUMMARY STATUS
// *

export type SummaryStatus = 'NOT_CLOSED' | 'PAY_TODAY' | 'PAID' | 'PROGNOSIS'

export const summaryStatusColors = (summaryStatus: SummaryStatus ,theme: MantineTheme): { color: string, background: string } => {
    switch (summaryStatus) {
        case 'NOT_CLOSED' : return {  color: theme.colors.primary[5], background:theme.fn.rgba(theme.colors.primary[5], 0.2) }
        case 'PAY_TODAY': return { color: theme.colors.info[5], background:theme.fn.rgba(theme.colors.info[5], 0.2) }
        case 'PAID': return { color: theme.colors.success[5], background:theme.fn.rgba(theme.colors.success[5], 0.2) }
        case 'PROGNOSIS': return { color: theme.colors.gray[6], background:theme.fn.rgba(theme.colors.gray[6], 0.2) }
        default: return { color: theme.colors.natural[5], background:theme.fn.rgba(theme.colors.natural[4], 0.2) }
    }
}

// *
// WORK STATUS
// *

export type WorkStatus = | 'PREPARATION' | 'WAIT' | 'DONE' | 'PROBLEM' | 'CONTROL' | 'REVISION' | 'PAID' | 'FINISHED'

export const workStatusColorsFinance = (workStatus: WorkStatus, theme: MantineTheme): { color: string, background: string } => {
    switch (workStatus) {
        case 'PREPARATION' : return { color: theme.colors.primary[5], background:theme.fn.rgba(theme.colors.primary[5], 0.2) }
        case 'WAIT': return { color: theme.colors.primary[5], background:theme.fn.rgba(theme.colors.primary[5], 0.2) }
        case 'DONE': return { color: theme.colors.info[5], background:theme.fn.rgba(theme.colors.info[5], 0.2) }
        case 'PROBLEM': return { color: theme.colors.primary[5], background:theme.fn.rgba(theme.colors.primary[5], 0.2) }
        case 'CONTROL': return { color: theme.colors.primary[5], background:theme.fn.rgba(theme.colors.primary[5], 0.2) }
        case 'REVISION': return{ color: theme.colors.primary[5], background:theme.fn.rgba(theme.colors.primary[5], 0.2) }
        case 'PAID': return { color: theme.colors.success[5], background:theme.fn.rgba(theme.colors.success[5], 0.2) }
        case 'FINISHED': return { color: theme.colors.info[5], background:theme.fn.rgba(theme.colors.info[5], 0.2) }
        default: return { color: theme.colors.natural[6], background:theme.fn.rgba(theme.colors.natural[6], 0.2) }
    }
}

export const workStatusColors = (workStatus: WorkStatus, theme: MantineTheme): { color: string, background: string } => {
    switch (workStatus) {
        case 'PREPARATION' : return { color: theme.colors.natural[6], background:theme.fn.rgba(theme.colors.natural[6], 0.2) }
        case 'WAIT': return { color: theme.colors.info[5], background:theme.fn.rgba(theme.colors.info[5], 0.2) }
        case 'CONTROL': return { color: theme.colors.warning[5], background:theme.fn.rgba(theme.colors.primary[3], 0.2) }
        case 'REVISION': return { color: theme.colors.info[5], background:theme.fn.rgba(theme.colors.info[5], 0.2) }
        case 'PROBLEM': return { color: theme.colors.red[5], background:theme.fn.rgba(theme.colors.red[5], 0.2) }
        case 'DONE': return { color: theme.colors.success[5], background:theme.fn.rgba(theme.colors.success[5], 0.2) }
        case 'PAID': return { color: theme.colors.warning[5], background:theme.fn.rgba(theme.colors.warning[5], 0.2) }
        case 'FINISHED': return { color: theme.colors.info[5], background:theme.fn.rgba(theme.colors.info[5], 0.2) }
        default: return { color: theme.colors.natural[6], background:theme.fn.rgba(theme.colors.natural[6], 0.2) }
    }
}

// *
// DATA CONVERSION
// *

export const getSummaryData = (data: PerformerFinanceSummary): { id: SummaryStatus, title: string, data: number, order: number }[] => Object.entries(data).map((item) => {
    if(item[0] === 'payed') {
        return {
            id: 'PAID',
            title: 'Оплачено',
            data: item[1],
            order: 0,
        }
    } else if(item[0] === 'payToday') {
        return {
            id: 'PAY_TODAY',
            title: 'К выплате',
            data: item[1],
            order: 1,
        }
    } else if(item[0] === 'notClosed') {
        return {
            id: 'NOT_CLOSED',
            title: 'Не завершенно',
            data: item[1],
            order: 2,
        }
    } else {
        return {
            id: 'PROGNOSIS',
            title: 'Прогноз месячного заработка',
            data: item[1],
            order: 3,
        }
    }
})

export const getWorkStatusDataFinance = (status: WorkStatus) => {
    switch(status) {
        case 'PREPARATION': return { title: 'На подготовке', icon: <IconClock size={ '12px' }/> }
        case 'FINISHED': return { title: 'Завершена', icon: <IconCheck size={ '12px' }/> }
        case 'WAIT': return { title: 'Ждёт выполнения', icon: <IconPlayerPlay size={ '12px' }/> }
        case 'CONTROL': return { title: 'Ждёт контроля', icon: <IconRefresh size={ '12px' }/> }
        case 'REVISION': return { title: 'На доработке', icon: <IconRefresh size={ '12px' }/> }
        case 'PROBLEM': return { title: 'Инцидент', icon: <IconInfoTriangle size={ '12px' }/> }
        case 'DONE': return { title: 'Завершена', icon: <IconCheck size={ '12px' }/> }
        case 'PAID': return { title: 'Оплаченно', icon: <IconCurrencyRubel size={ '12px' }/> }
        default: return { title: 'На подготовке', icon: <IconClock size={ '12px' }/> }
    }
}