import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import { REDIRECT_ROUTES } from 'routes/redirect/routes'
import { ERROR_ROUTES } from '../error/routes'
import { WorkPageLayout } from 'components/Worker/Work/WorkPageLayout.tsx';
import { WorkLoadWrapper } from 'components/Worker/Work/WorkLoadWrapper.tsx';
import { createElement } from 'react';
import BreadcrumbItem from 'components/AbstractComponents/BreadCrumbs/BreadcrumbItem.tsx';
import { RootStateType } from 'store/store.ts';
import { OverdueLayout } from 'components/Worker/Overdue/OverdueLayout.tsx';
import { UserProfileLayout } from 'components/Worker/User/UserProfileLayout.tsx';


export const WORKER_ROUTES: Array<RouteObject> = [
    {
        id: 'worker',
        path: '/',
        async lazy() {
            const { LayoutWorker } = await import('components/AbstractComponents/LayoutWorker/LayoutWorker')
            return { element: <LayoutWorker/> }
        },
        children: [
            ...ERROR_ROUTES,
            ...REDIRECT_ROUTES,
            {
                id: 'dashboard',
                path: '/',
                element: <Outlet/>,
                loader: () => ({
                    id: 'scheduler',
                    name: 'Расписание',
                    path: '/',
                }),
                children: [
                    {
                        index: true,
                        async lazy() {
                            const { DashboardPage } = await import('components/Worker/DashboardPage.tsx')
                            return { element: <DashboardPage/> }
                        },
                    },
                    {
                        id: 'trouble',
                        path: 'trouble',
                        async lazy() {
                            const { TroubleLayout } = await import('components/Worker/Task/Trouble/TroubleLayout.tsx')
                            return { element: <TroubleLayout/> }
                        },
                    },
                    {
                        id: 'workerNotifications',
                        path: '/notifications',
                        async lazy() {
                            const { NotificationPage } = await import('components/AbstractComponents/Notifications/NotificationPage.tsx')
                            return { element: <NotificationPage/> }
                        }
                    },
                    {
                        id: 'docsList',
                        path: 'docs',
                        element: <Outlet />,
                        loader: () => ( {
                            name: 'Список статей',
                            path: 'docs/docs',
                        } ),

                        children: [
                            {
                                index: true,
                                loader: ({ params }) => ({ id: params.id, name: 'База знаний', path: '/docs' }),
                                async lazy() {
                                    const { DocsPage } = await import('src/components/Docs/DocsPage.tsx')
                                    return { element: <DocsPage/> }
                                },
                            },

                            {
                                id: 'docById',
                                path: ':docId',
                                loader: ({ params }) => ( {
                                    id: params.docId,
                                    name: createElement(BreadcrumbItem((state: RootStateType) => ( {
                                        name: typeof state.docsReducer.article?.title == 'string' ?  state.docsReducer.article.title : '',
                                    } ))),
                                } ),
                                async lazy() {
                                    const { ArticlePage } = await import('src/components/Docs/ArticlePage.tsx')
                                    return { element: <ArticlePage />
                                    }
                                },
                                children: [
                                    {
                                        id: 'nestedArticleById',
                                        path: ':docId',
                                        async lazy() {
                                            const { ArticlePage } = await import('src/components/Docs/ArticlePage.tsx')
                                            return { element: <ArticlePage />
                                            }
                                        },
                                    }
                                ]
                            }
                        ],
                    },
                    {
                        id: 'work',
                        path: 'work/:workId',
                        element: 
                            <WorkLoadWrapper>
                                <Outlet />
                            </WorkLoadWrapper>
                        ,
                        loader: ({ params }) => ({
                            id: params.id,
                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                name: state?.activeWorkReducer?.storeActiveWork?.name || '',
                            }))),
                            path: '/',
                        }),
                        children: [
                            {
                                id: 'workId',
                                path: '',
                                element: <WorkPageLayout variant={'calendar'} />,
                            },
                            {
                                id: 'task',
                                path: 'task/:taskId',
                                async lazy() {
                                    const { WorkerTaskPage } = await import('components/Worker/Task/WorkerTaskPage')
                                    return {
                                        element: <WorkerTaskPage/>,
                                    }
                                },
                                loader: ({ params }) => ({ 
                                    id: params.taskId,
                                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                        name: state.workerTaskReducer.editableFormHeader.name || '',
                                    }))),
                                    path: `work/${params.workId}/task/${params.taskId}`,
                                }),
                                children: [
                                    {
                                        index: true,
                                        element: <Navigate to={'steps'} replace />,
                                    },
                                    {
                                        id: 'steps',
                                        path: 'steps',
                                        loader: () => ({ active: 'steps' }),
                                        async lazy() {
                                            const { WorkerStepsTab } = await import('components/Worker/Task/PageBody/Steps/WorkerStepsTab')
                                            return { element: <WorkerStepsTab/> }
                                        },
                                        children: [
                                            // {
                                            //     id: 'step',
                                            //     path: ':stepId',
                                            //     async lazy() {
                                            //         const { StepTab } = await import('components/Worker/Task/PageBody/Steps/StepTab')
                                            //         return { element: <StepTab/> }
                                            //     },
                                            // }
                                        ],
                                    },
                                    {
                                        id: 'comments',
                                        path: 'comments',
                                        loader: () => ({ active: 'comments' }),
                                        async lazy() {
                                            const { CommentsTab } = await import('components/Worker/Task/PageBody/Comments/CommentsTab')
                                            return { element: <CommentsTab/> }
                                        },
                                    },
                                    {
                                        id: 'info',
                                        path: 'info',
                                        loader: () => ({ active: 'comments' }),
                                        async lazy() {
                                            const { InformationTab } = await import('components/Worker/Task/PageBody/Information/InformationTab')
                                            return { element: <InformationTab/> }
                                        },
                                    },
                                    {
                                        id: 'materials',
                                        path: 'materials',
                                        async lazy() {
                                            const { MaterialsTab } = await import('components/Worker/Task/PageBody/Materials/MaterialsTab')
                                            return { element: <MaterialsTab/> }
                                        },
                                    },
                                ],
                            },

                        ],
                    },
                    {
                        id: 'overdue',
                        path: 'overdue',
                        element: <Outlet />,
                        loader: ({ params }) => ({
                            id: params.id,
                            name: 'Список задолженностей',
                            path: '/overdue',
                        }),
                        children: [
                            {
                                index: true,
                                element: <OverdueLayout />,
                            },
                            {
                                id: 'workOverdue',
                                path: 'work/:workId',
                                element:
                                    <WorkLoadWrapper>
                                        <Outlet />
                                    </WorkLoadWrapper>
                                ,
                                loader: ({ params }) => ({
                                    id: params.id,
                                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                        name: state?.activeWorkReducer?.storeActiveWork?.name || '',
                                    }))),
                                    path: '/',
                                }),
                                children: [
                                    {
                                        id: 'workIdOverdue',
                                        path: '',
                                        element: <WorkPageLayout variant={'overdue'} />,
                                    },
                                    {
                                        id: 'taskOverdue',
                                        path: 'task/:taskId',
                                        async lazy() {
                                            const { WorkerTaskPage } = await import('components/Worker/Task/WorkerTaskPage')
                                            return {
                                                element: <WorkerTaskPage/>,
                                            }
                                        },
                                        loader: ({ params }) => ({
                                            id: params.taskId,
                                            name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                                name: state.workerTaskReducer.editableFormHeader.name || '',
                                            }))),
                                            path: `work/${params.workId}/task/${params.taskId}`,
                                        }),
                                        children: [
                                            {
                                                index: true,
                                                element: <Navigate to={'steps'} replace />,
                                            },
                                            {
                                                id: 'stepsOverdue',
                                                path: 'steps',
                                                loader: () => ({ active: 'steps' }),
                                                async lazy() {
                                                    const { WorkerStepsTab } = await import('components/Worker/Task/PageBody/Steps/WorkerStepsTab')
                                                    return { element: <WorkerStepsTab/> }
                                                },
                                                children: [
                                                    // {
                                                    //     id: 'step',
                                                    //     path: ':stepId',
                                                    //     async lazy() {
                                                    //         const { StepTab } = await import('components/Worker/Task/PageBody/Steps/StepTab')
                                                    //         return { element: <StepTab/> }
                                                    //     },
                                                    // }
                                                ],
                                            },
                                            {
                                                id: 'commentsOverdue',
                                                path: 'comments',
                                                loader: () => ({ active: 'comments' }),
                                                async lazy() {
                                                    const { CommentsTab } = await import('components/Worker/Task/PageBody/Comments/CommentsTab')
                                                    return { element: <CommentsTab/> }
                                                },
                                            },
                                            {
                                                id: 'infoOverdue',
                                                path: 'info',
                                                loader: () => ({ active: 'comments' }),
                                                async lazy() {
                                                    const { InformationTab } = await import('components/Worker/Task/PageBody/Information/InformationTab')
                                                    return { element: <InformationTab/> }
                                                },
                                            },
                                            {
                                                id: 'materialsOverdue',
                                                path: 'materials',
                                                async lazy() {
                                                    const { MaterialsTab } = await import('components/Worker/Task/PageBody/Materials/MaterialsTab')
                                                    return { element: <MaterialsTab/> }
                                                },
                                            },
                                        ],
                                    },
                                
                                ],
                            },
                        ],
                    },
                    {
                        id: 'profile',
                        path: 'profile',
                        async lazy() {
                            const { Profile } = await import('components/Worker/Profile/Profile.tsx')
                            return { element: <Profile/> }
                        },
                        loader: ({ params }) => ({ id: params.id, name: 'Личный кабинет' }),
                        children: [
                            {
                                id: 'account',
                                path: 'account',
                                loader: () => ({ active: 'account' }),
                                async lazy() {
                                    const { AccountTab } = await import('components/Worker/Profile/Account/AccountTab.tsx')

                                    return { element: <AccountTab/> }
                                },
                            },
                            {
                                id: 'money',
                                path: 'money',
                                loader: () => ({ active: 'money' }),
                                async lazy() {
                                    const { MoneyTab } = await import('components/Worker/Profile/Money/MoneyTab.tsx')

                                    return { element: <MoneyTab/> }
                                },
                            },
                            {
                                id: 'scheduler',
                                path: 'scheduler',
                                async lazy() {
                                    const { SchedulerTab } = await import('components/Worker/Profile/Scheduler/SchedulerTab.tsx')

                                    return { element: <SchedulerTab/> }
                                },
                            },
                        ],
                    },
                    {
                        id: 'user',
                        path: 'user/:userId',
                        element: <Outlet />,

                        loader: ({ params }) => ({
                            id: params.id,
                            name: 'Профиль',
                            path: '/',
                        }),
                        children: [
                            {
                                id: 'userId',
                                path: '',
                                element: <UserProfileLayout />,
                            },
                        ]
                    },
                    {
                        id: 'documentMovementOrders',
                        path: 'supply/document-movement-orders/:orderId',
                        loader: () => ( {
                            name: 'Ордера',
                            path: 'supply/document-movement-orders',
                        } ),
                        children: [
                            {
                                id: 'orderById',
                                path: '',
                                async lazy() {
                                    const { OrderPage } = await import('components/Supply/Orders/OrderPage')
                                    return { element: <OrderPage/> }
                                },
                                loader: ({ params }) => ({
                                    id: params.orderId,
                                    name: createElement(BreadcrumbItem((state: RootStateType) => ({
                                        name: state.orderMaterialsReducer.documentMovementOrderData?.name || '',
                                    }))),
                                    path: `supply/document-movement-orders/${ params.orderId }`,
                                }),
                                children: [
                                    {
                                        index: true,
                                        async lazy() {
                                            const { OrderBodyView } = await import('components/Supply/Orders/PageBody/Desktop/OrderBodyView.tsx')
                                            return { element: <OrderBodyView /> }
                                        },
                                    },
                                    {
                                        path: 'execute',
                                        id: 'orderExecute',
                                        async lazy() {
                                            const { OrderMaterialsTableEditWrapper } = await import('components/Supply/Orders/PageBody/Desktop/OrderMaterials/OrderMaterialsTableEdit/OrderMaterialsTableEditWrapper.tsx')
                                            return { element: <OrderMaterialsTableEditWrapper /> }
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
]
