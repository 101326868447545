import { emptyApi as api } from './emptyApi';
export const addTagTypes = ['Materials', 'MaterialVariants'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            materialsFindOne: build.query<
                MaterialsFindOneApiResponse,
                MaterialsFindOneApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/resources/material/${queryArg}`,
                }),
                providesTags: ['Materials', 'MaterialVariants'],
            }),
            materialUpdate: build.mutation<
                MaterialUpdateApiResponse,
                MaterialUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/resources/material/${queryArg.id}`,
                    method: 'PUT',
                    body: queryArg.updateMaterialRequestDto,
                }),
                invalidatesTags: ['Materials', 'MaterialVariants'],
            }),
            materialDelete: build.mutation<
                MaterialDeleteApiResponse,
                MaterialDeleteApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/resources/material/${queryArg}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Materials', 'MaterialVariants'],
            }),
            materialsFind: build.query<MaterialsFindApiResponse, MaterialsFindApiArg>(
                {
                    query: (queryArg) => ({
                        url: '/api/v1/resources/material',
                        params: {
                            limit: queryArg.limit,
                            offset: queryArg.offset,
                            filter: queryArg.filter,
                            sort: queryArg.sort,
                        },
                    }),
                    providesTags: ['Materials', 'MaterialVariants'],
                }
            ),
            materialCreate: build.mutation<
                MaterialCreateApiResponse,
                MaterialCreateApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/resources/material',
                    method: 'POST',
                    body: queryArg,
                }),
                invalidatesTags: ['Materials', 'MaterialVariants'],
            }),
            materialsFindInStocksByMaterial: build.query<
                MaterialsFindInStocksByMaterialApiResponse,
                MaterialsFindInStocksByMaterialApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/resources/material/availability/material/${queryArg}`,
                }),
                providesTags: ['Materials'],
            }),
            materialsReport: build.query<
                MaterialsReportApiResponse,
                MaterialsReportApiArg
            >({
                query: (queryArg) => ({
                    url: '/api/v1/resources/material/report',
                    params: {
                        limit: queryArg.limit,
                        offset: queryArg.offset,
                        filter: queryArg.filter,
                        sort: queryArg.sort,
                    },
                }),
                providesTags: ['Materials', 'MaterialVariants'],
            }),
            materialManualUpdate: build.mutation<
                MaterialManualUpdateApiResponse,
                MaterialManualUpdateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/v1/resources/material/manual-update/${queryArg}`,
                    method: 'PUT',
                }),
                invalidatesTags: ['Materials', 'MaterialVariants'],
            }),
            materialExportJobMaterials: build.mutation<
                MaterialExportJobMaterialsApiResponse,
                MaterialExportJobMaterialsApiArg
            >({
                query: () => ({
                    url: '/api/v1/resources/material/export-jobs-materials',
                    method: 'POST',
                }),
                invalidatesTags: ['Materials'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as materialsApi };
export type MaterialsFindOneApiResponse = /** status 200  */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindMaterialResponseDto;
};
export type MaterialsFindOneApiArg = string;
export type MaterialUpdateApiResponse =
  /** status 200  */ FindMaterialResponseDto;
export type MaterialUpdateApiArg = {
    id: string;
    updateMaterialRequestDto: UpdateMaterialRequestDto;
};
export type MaterialDeleteApiResponse = /** status 200  */ void;
export type MaterialDeleteApiArg = string;
export type MaterialsFindApiResponse = /** status 200 ОК */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindMaterialResponseDto[];
    pagination: {
        offset: number;
        limit: number;
        total: number;
    };
};
export type MaterialsFindApiArg = {
    /** Кол-во возвращаемых элементов */
    limit?: number;
    /** Сдвиг значений */
    offset?: number;
    /** Фильтры */
    filter?: string;
    /** Сортировка */
    sort?: string;
};
export type MaterialCreateApiResponse =
  /** status 201  */ FindMaterialResponseDto;
export type MaterialCreateApiArg = CreateMaterialRequestDto;
export type MaterialsFindInStocksByMaterialApiResponse = /** status 200  */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindMaterialInStockResponseDto[];
};
export type MaterialsFindInStocksByMaterialApiArg = string;
export type MaterialsReportApiResponse = /** status 200 ОК */ {
    metadata: {
        id: string;
        permissions: string[];
    }[];
    data: FindMaterialReportResponseDto[];
    pagination: {
        offset: number;
        limit: number;
        total: number;
    };
};
export type MaterialsReportApiArg = {
    /** Кол-во возвращаемых элементов */
    limit?: number;
    /** Сдвиг значений */
    offset?: number;
    /** Фильтры */
    filter?: string;
    /** Сортировка */
    sort?: string;
};
export type MaterialManualUpdateApiResponse = unknown;
export type MaterialManualUpdateApiArg = string;
export type MaterialExportJobMaterialsApiResponse = unknown;
export type MaterialExportJobMaterialsApiArg = void;
export type FindMaterialStocksResponseDto = {
    id: string;
    title: string;
    remainsAmount: number;
};
export type FindCategoryResponseDto = {
    id: string;
    name: string;
    url: string | null;
};
export type FindStoreResponseDto = {
    id: string;
    name: string;
    url: string | null;
};
export type FindStoreCategoryResponseDto = {
    id: string;
    name: string;
    url: string | null;
    store: FindStoreResponseDto;
};
export type FindBucketResponseDto = {
    id: string;
    /** Bucket name */
    name: string;
    /** Bucket path */
    path: string;
};
export type FindFileResponseDto = {
    id: string;
    /** File name */
    name: string;
    /** File mime type */
    type: string;
    /** File size in bytes */
    size: number;
    /** File bucket */
    bucket: FindBucketResponseDto;
};
export type FindBrandResponseDto = {
    id: string;
    name: string;
    url: string | null;
    image: FindFileResponseDto | null;
};
export type JobTypeResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindMaterialTypeResponseDto = {
    id: string;
    name: string;
    description: string;
    jobType: JobTypeResponseDto;
};
export type FindPackageResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindInventoryTypeResponseDto = {
    id: string;
    name: string;
    type: 'MATERIAL' | 'PRODUCT';
};
export type FindMaterialPackResponseDto = {
    id: string;
    packing: number;
    unitMeasure: string;
    price: number;
    tradePrice: number;
    default: boolean;
    proportion: number;
};
export type FindMaterialResponseDto = {
    id: string;
    updatedAt: string;
    sku: string | null;
    name: string;
    description: string;
    url: string;
    price: number;
    discountPrice: number | null;
    isChecked: boolean;
    isAvailable: boolean;
    images: string[];
    isAccountable: boolean;
    stocks: FindMaterialStocksResponseDto[];
    category: FindCategoryResponseDto;
    storeCategory: FindStoreCategoryResponseDto;
    store: FindStoreResponseDto;
    brand: FindBrandResponseDto;
    type: FindMaterialTypeResponseDto;
    package: FindPackageResponseDto;
    inventoryType: FindInventoryTypeResponseDto;
    packs: FindMaterialPackResponseDto[];
};
export type ApiError = {
    status: number;
    message: string[];
    error: string;
};
export type UpdateMaterialRequestDto = {
    /** Уникальный индентификатор материала материала */
    uid?: string | null;
    /** Артикул материала */
    sku?: string | null;
    /** Название материала */
    name: string;
    /** Описание материала */
    description: string;
    /** Ссылка на материал */
    url?: string | null;
    /** Цена на материал */
    price: number;
    /** Цена со скидкой на материал */
    discountPrice?: number | null;
    isAccountable?: boolean | null;
    isChecked?: boolean | null;
    /** Изображения материала */
    images: string[];
    /** Идентификатор категории */
    categoryId?: string;
    /** Идентификатор бренд */
    brandId?: string | null;
    /** Идентификатор типа */
    typeId?: string | null;
    /** Идентификатор упаковки */
    packageId?: string | null;
    /** Идентификатор типа ТМЦ */
    inventoryTypeId?: string | null;
    /** Идентификатор магазина */
    storeId?: string | null;
};
export type CreateMaterialRequestDto = {
    /** Уникальный индентификатор материала материала */
    uid?: string | null;
    /** Артикул материала */
    sku?: string | null;
    /** Название материала */
    name: string;
    /** Описание материала */
    description: string;
    /** Ссылка на материал */
    url?: string | null;
    /** Цена на материал */
    price: number;
    /** Цена со скидкой на материал */
    discountPrice?: number | null;
    isAccountable?: boolean | null;
    isChecked?: boolean | null;
    /** Изображения материала */
    images: string[];
    /** Идентификатор категории */
    categoryId?: string;
    /** Идентификатор бренд */
    brandId?: string | null;
    /** Идентификатор типа */
    typeId?: string | null;
    /** Идентификатор упаковки */
    packageId?: string | null;
    /** Идентификатор типа ТМЦ */
    inventoryTypeId?: string | null;
    /** Идентификатор магазина */
    storeId?: string | null;
};
export type FindMaterialInStockResponseDto = {
    storeId: string;
    storeName: string;
    inStock: number;
};
export type FindJobTypeResponseDto = {
    id: string;
    name: string;
    description: string;
    materialType: FindMaterialTypeResponseDto;
};
export type FindJobViewResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindJobStageResponseDto = {
    id: string;
    name: string;
    description: string;
    sort: number;
};
export type FindJobSurfaceResponseDto = {
    id: string;
    name: string;
    description: string;
};
export type FindSpecializationResponseDto = {
    id: string;
    name: string;
};
export type FindOrganizationResponseDto = {
    id: string;
    name: string;
};
export type FindJobGroupResponseDto = {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    jobs: FindJobResponseDto[];
};
export type FindJobResponseDto = {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
    insideDescription?: string | null;
    clientDescription?: string | null;
    materialsCount: number;
    isNoisiness: boolean;
    isInvisible: boolean;
    type: FindJobTypeResponseDto;
    package?: FindPackageResponseDto | null;
    view?: FindJobViewResponseDto | null;
    stage: FindJobStageResponseDto;
    surfaces?: FindJobSurfaceResponseDto[] | null;
    specializations: FindSpecializationResponseDto[];
    organization: FindOrganizationResponseDto;
    groups: FindJobGroupResponseDto[];
};
export type FindMaterialReportResponseDto = {
    id: string;
    name: string;
    url: string;
    price: number;
    discountPrice: number | null;
    isChecked: boolean;
    isAvailable: boolean;
    isAccountable: boolean;
    category: FindCategoryResponseDto;
    store: FindStoreResponseDto;
    brand: FindBrandResponseDto;
    type: FindMaterialTypeResponseDto;
    package: FindPackageResponseDto;
    jobs: FindJobResponseDto[];
};
export const {
    useMaterialsFindOneQuery,
    useLazyMaterialsFindOneQuery,
    useMaterialUpdateMutation,
    useMaterialDeleteMutation,
    useMaterialsFindQuery,
    useLazyMaterialsFindQuery,
    useMaterialCreateMutation,
    useMaterialsFindInStocksByMaterialQuery,
    useLazyMaterialsFindInStocksByMaterialQuery,
    useMaterialsReportQuery,
    useLazyMaterialsReportQuery,
    useMaterialManualUpdateMutation,
    useMaterialExportJobMaterialsMutation,
} = injectedRtkApi;
