import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg, multiselectFieldDefault } from 'utils/inputs'
import {
    WarehouseFormBody,
    WarehouseFormHeader,
    WarehousesAutocompleteKey,
    WarehousesFormGlobal,
    WarehousesMultiselectKey,
    WarehouseTabs, WarehouseTabsPath
} from './Warehouses.types'
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs'
import { FindWarehouseResponseDto, UpdateWarehouseRequestDto } from 'store/api/warehouse'

// *
// DATA CONVERT FUNCS
// *

export const convertWarehouseDtoToFormBody = (warehouse: FindWarehouseResponseDto): WarehouseFormBody => ( {
    address: warehouse?.address || warehouseFormBodyInitialState.address,
    description: warehouse?.description || warehouseFormBodyInitialState.description,
    type: warehouse?.type && {
        name: warehouse.type.name,
        data: {
            id: warehouse.type.id,
            name: warehouse.type.name,
        },
    } || warehouseFormBodyInitialState.type,
    types: multiselectFieldDefault,
    object: warehouse?.object && {
        name: warehouse.object.name,
        data: {
            id: warehouse.object.id,
            name: warehouse.object.name,
        },
    } || warehouseFormBodyInitialState.object,
    stockman: warehouse?.stockman ? {
        name: warehouse.stockman.name,
        data: {
            id: warehouse.stockman.id,
            name: warehouse.stockman.name,
        },
    } : {
        name: '',
        data: {
            id: '',
            name: '',
        }
    },
    deleted: warehouse?.deleted || false,
    organization: warehouse?.organization && {
        name: warehouse.organization.name,
        data: {
            id: warehouse.organization.id,
            name: warehouse.organization.name,
        },
    } || warehouseFormBodyInitialState.organization,
} )
export const convertWarehouseDtoToFormHeader = (warehouse: FindWarehouseResponseDto): WarehouseFormHeader => ( {
    name: warehouse?.name || warehouseFormHeaderInitialState.name,
} )
export const convertWarehouseFormToRequestDto = (data: Partial<WarehouseFormBody & WarehouseFormHeader>): UpdateWarehouseRequestDto => ( {
    name: data?.name || warehouseFormHeaderInitialState.name,
    address: data?.address || warehouseFormBodyInitialState.address,
    description: data?.description || warehouseFormBodyInitialState.description,
    typeId: data?.type && data?.type?.data.id || warehouseFormBodyInitialState.type.data.id,
    objectId: data?.object && data?.object?.data.id || warehouseFormBodyInitialState.object.data.id,
    organizationId: data?.organization && data?.organization?.data.id
        || warehouseFormBodyInitialState.organization.data.id,
    stockmanId: data?.stockman && data?.stockman?.data.id
        || warehouseFormBodyInitialState.stockman.data.id,
    deleted: data?.deleted || false,
} )

// *
// STORE
// *

export const warehousesFormGlobalDefaultValues: WarehousesFormGlobal = {
    name: '',
    address: '',
    description: '',
    object: autocompleteFieldDefault,
    organization: autocompleteFieldDefault,
    type: autocompleteFieldDefault,
    types: multiselectFieldDefault,
    stockman: autocompleteFieldDefault,
    deleted: false,
}
export const warehouseFormHeaderInitialState: WarehouseFormHeader = {
    name: '',
}
export const warehouseFormBodyInitialState: WarehouseFormBody = {
    address: '',
    description: '',
    object: autocompleteFieldDefault,
    organization: autocompleteFieldDefault,
    type: autocompleteFieldDefault,
    types: multiselectFieldDefault,
    stockman: autocompleteFieldDefault,
    deleted: false,
}

// *
// TABS FUNCS
// *

export const warehouseTabs: WarehouseTabs = {
    about: 'На складе',
    inventories: 'Инвентаризация',
    'supply-requests': 'Заявки на снабжение',
    'document-supplier-purchases': 'Заказы поставщикам',
    'document-movements': 'Перемещения',
    orders: 'Ордера'
    
}

export const getWarehouseActiveTab = (pathname: string): WarehouseTabsPath =>
    pathname.includes('/about') ? 'about' :
        pathname.includes('/inventories') ? 'inventories' :
            pathname.includes('/supply-requests') ? 'supply-requests' :
                pathname.includes('/document-supplier-purchases') ? 'document-supplier-purchases' :
                    pathname.includes('/document-movements') ? 'document-movements' :
                        pathname.includes('/orders') ? 'orders' :
                            'about'

// *
// INPUT PROCESSING FUNCS
// *

type WarehousesObjectAutocompleteSubmitData = AutocompleteSubmitData & { label: string }

export const onWarehousesGlobalAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<WarehousesAutocompleteKey, WarehousesFormGlobal>) =>
    (data: WarehousesObjectAutocompleteSubmitData) => {
        if(data.value && data.label) {
            setFormValue(key, autocompleteSubmitArg({ id: data.value, value: data.label }))
            return
        }
        setFormValue(key, autocompleteSubmitArg(data))
    }

export const onWarehousesGlobalAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<WarehousesAutocompleteKey, WarehousesFormGlobal>) =>
    (label: string) => {
        if (formData[key]?.name !== label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }
export const onWarehousesGlobalMultiselectChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<WarehousesMultiselectKey, WarehousesFormGlobal>) => (label: string) => {
    if (formData[key]?.name !== label) {
        setFormValue(`${ key }.name`, label)
    }
}
export const onWarehousesGlobalMultiselectSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<WarehousesMultiselectKey, WarehousesFormGlobal>) => (valueIds: Array<string>) => {
    setFormValue(`${ key }.ids`, valueIds)
}

// body

type WarehousesObjectBodyAutocompleteSubmitData = AutocompleteSubmitData & { label: string }

export const onWarehouseBodyAutocompleteSubmit = ({
    key,
    setFormValue,
}: InputSubmitArg<WarehousesAutocompleteKey, WarehouseFormBody>) =>
    (data: WarehousesObjectBodyAutocompleteSubmitData) => {
        if(data.value && data.label) {
            setFormValue(key, autocompleteSubmitArg({ id: data.value, value: data.label }))
            return
        }
        setFormValue(key, autocompleteSubmitArg(data))
    }
export const onWarehouseBodyAutocompleteChange = ({
    formData,
    key,
    setFormValue,
}: InputChangeArg<WarehousesAutocompleteKey, WarehouseFormBody>) =>
    (label: string) => {
        if (formData[key]?.name !== label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }
