import { autocompleteChangeArg, autocompleteFieldDefault, autocompleteSubmitArg } from 'utils/inputs'
import { UserRoleFormBody, UserRoleFormHeader, UserRolesAutocompleteKey, UserRolesFormGlobal } from './UserRoles.types'
import { AutocompleteSubmitData, InputChangeArg, InputSubmitArg } from 'types/inputs'
import { FindRoleResponseDto, UpdateRoleRequestDto } from 'store/api/userRole'


// *
// DATA CONVERT FUNCS
// *

export const convertUserRoleDtoToFormBody = (userRole: FindRoleResponseDto): UserRoleFormBody => ({
    enabled: userRole?.enabled || userRoleFormBodyInitialState.enabled,
    visible: userRole?.visible || userRoleFormBodyInitialState.visible,
    organization: userRole?.organization && { name: userRole.organization.name, data: { id: userRole.organization.id, name: userRole.organization.name } } || userRoleFormBodyInitialState.organization,
    permissions: userRole.permissions
})
export const convertUserRoleDtoToFormHeader = (userRole: FindRoleResponseDto): UserRoleFormHeader => ({
    name: userRole?.name || userRoleFormHeaderInitialState.name,
})
export const convertUserRoleFormToRequestDto = (data: Partial<UserRoleFormBody & UserRoleFormHeader>): UpdateRoleRequestDto => ({
    name: data?.name || userRoleFormHeaderInitialState.name,
    enabled: data?.enabled || userRoleFormBodyInitialState.enabled,
    visible: data?.visible || userRoleFormBodyInitialState.visible,
    organizationId: data?.organization?.data?.id || data?.organization?.name || userRoleFormBodyInitialState.organization.data.id,
    permissions: data?.permissions || null,

})

// *
// STORE
// *

export const userRolesFormGlobalDefaultValues: UserRolesFormGlobal = {
    name: '',
    organization: autocompleteFieldDefault,
    permissions: [],
    enabled: false,
    visible: false,
}
export const userRoleFormHeaderInitialState: UserRoleFormHeader = {
    name: '',
}
export const userRoleFormBodyInitialState: UserRoleFormBody = {
    organization: autocompleteFieldDefault,
    enabled: false,
    visible: false,
    permissions: [],
}


// *
// INPUT PROCESSING FUNCS
// *

// global
export const onUserRolesGlobalAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<UserRolesAutocompleteKey, UserRolesFormGlobal>) =>
    (data: AutocompleteSubmitData) => {
        setFormValue(key, autocompleteSubmitArg(data))
    }
export const onUserRolesGlobalAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<UserRolesAutocompleteKey, UserRolesFormGlobal>) =>
    (label: string) => {
        if (formData[key]?.name!==label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }

// body
export const onUserRoleBodyAutocompleteSubmit = ({ key, setFormValue }: InputSubmitArg<UserRolesAutocompleteKey, UserRoleFormBody>) =>
    (data: AutocompleteSubmitData) => {
        setFormValue(key, autocompleteSubmitArg(data))
    }
export const onUserRoleBodyAutocompleteChange = ({ formData, key, setFormValue }: InputChangeArg<UserRolesAutocompleteKey, UserRoleFormBody>) =>
    (label: string) => {
        if (formData[key]?.name!==label) {
            setFormValue(key, autocompleteChangeArg(label))
        }
    }
