import { createAppSlice } from 'utils/createAppSlice'
import { defaultInitialState } from 'utils/defaultInitialState.ts'
import {
    DocumentSupplierPurchasesSliceInitialState
} from 'components/Supply/DocumentSupplierPurchases/DocumentSupplierPurchases.types.ts';

const documentSuppliersSliceInitialState: DocumentSupplierPurchasesSliceInitialState = {
    ...defaultInitialState,
    sortParamsArray: [
        {
            param: 'documentSupplier.updatedAt',
            rule: 'desc',
        },
    ],
}

export const documentSuppliersSlice = createAppSlice<DocumentSupplierPurchasesSliceInitialState>(
    'documentSuppliers',
    documentSuppliersSliceInitialState,
)


export default documentSuppliersSlice.reducer